export default {
  auth: '/api/v3/auth/transporter/login',
  logout: '/api/v3/auth/logout',
  emailVerification: '/api/v3/transporter/register/validation',
  accountActivation: '/api/v3/transporter/register/activation',
  subAccountActivation: '/api/v3/transporter/register/activation/sub-account',
  ownUserInfo: '/api/v3/auth/whoami',
  role: '/api/v3/auth/role',
  register: '/api/v3/transporter/register',
  decodeToken: '/api/v3/transporter/register/activation',
  setResetPassword: '/api/v3/auth/transporter/reset-password',
  forgotPassword: '/api/v3/auth/user/transporter/reset-password',

  // user admin
  userAdminList: '/api/v3/auth/user/filter',
  userAdminCreate: '/api/v3/auth/user',
  userAdminDetail: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  userAdminReset: (companyUserId) => `/api/v3/auth/user/transporter/reset-password/${companyUserId}`,
  userAdminResend: (companyUserId) => `/api/v3/auth/user/${companyUserId}/resend-activation`,
  userAdminDelete: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  roleList: '/api/v3/auth/role/filter?appType=web_transporter',

  // locations
  locationList: '/api/v3/master/location/filter',
  locationDetail: '/api/v3/master/location',
  provinceList: '/api/v3/master/location/province/filter',
  cityList: (provinceId) => `/api/v3/master/location/province/${provinceId}/city/filter`,
  districtList: (cityId) => `/api/v3/master/location/city/${cityId}/district/filter`,
  subDistrictList: (districtId) => `/api/v3/master/location/district/${districtId}/sub-district/filter`,

  // registration
  createWorkflow: '/api/v3/transporter/company/register/create',
  companyRegistrationDraft: '/api/v3/transporter/company/register/draft',
  submitCompanyInformation: '/api/v3/transporter/company/register',
  companyDocumentSupport: '/api/v3/transporter/company/register/document',
  saveAttachment: (companyId) => `/api/v3/transporter/company/register/document/${companyId}/attachment`,

  // upload
  uploadImage: '/api/v3/file-upload',
  // download
  downloadImage: '/api/v3/file-upload',
  // delete
  deleteFile: '/api/v3/file-upload/delete',
  // uploadCsv: '/api/v1/orders/upload/csv',
  uploadedCsvStatus: '/api/v3/master',
  uploadedCsvStatusDriver: '/api/v3/master',

  // Legal Documents
  legalDocumentList: '/api/v3/management/company/register/legal',
  legalAttachmentList: '/api/v3/management/company/register/legal/attachment',

  // order
  orderList: '/api/v3/transporter/shipment',
  orderTransaction: '/api/v3/transporter/transaction',
  saveDriverChange: '/api/v3/transporter/shipment/assign/driver',
  waitingShipment: '/api/v3/transporter/shipment/show-sign',

  // Approval
  listInboxApproval: '/api/v3/workflow/page/inbox',
  listCompletedApproval: '/api/v3/workflow/page/completed',
  listApprovalInProcess: '/api/v3/workflow/page/progress',
  listHistoryApproval: '/api/v3/workflow/history',
  shipperRegistrationApprove: '/api/v3/shipper/company/register/action',
  transporterRegistrationApprove: '/api/v3/transporter/company/register/action',
  actionWorkflow: '/api/v3/contract/company/workflow',
  getWorkflow: '/api/v3/transporter/company/register',
  reviseWorkflow: (workflowId, workflowDetailId) => `/api/v3/transporter/company/register/revise/${workflowId}/${workflowDetailId}`,

  // driver
  driverList: '/api/v3/master/driver',
  activedDriver: (driverId) => `/api/v3/master/driver/${driverId}`,
  driverCreateEdit: '/api/v3/master/driver',
  driverDetail: (driverId) => `/api/v3/master/driver/${driverId}`,
  deleteDriver: (driverId) => `/api/v3/master/driver/${driverId}`,
  resetPassword: (driverId) => `/api/v3/master/driver/${driverId}/reset-password`,
  csvTemplateDataDriver: '/api/v3/master/driver/template',
  uploadCsvDriver: '/api/v3/master/driver/upload',
  topListDriver: '/api/v3/rating/driver',
  listVaksinDriver: '/api/v3/master/vaccines',

  // transport
  transportList: '/api/v3/master/transport',
  transportCreate: '/api/v3/master/transport',
  transportDetail: (transportId) => `/api/v3/master/transport/${transportId}`,
  driverTransport: '/api/v3/master/driver?size=20&isActive=true',
  driver: '/api/v3/master/driver/transport',
  transportType: '/api/v3/master/transport-type/filter',
  transportTypeLimit: (mouId, transportTypeLimitId) => `/api/v3/transporter/mou/${mouId}/transport-type-limit/${transportTypeLimitId}`,
  csvTemplateTransport: '/api/v3/master/transport/template',
  csvUploadTransport: '/api/v3/master/transport/upload',

  // invoice
  invoiceList: '/api/v3/master/invoice-data',
  invoiceCreate: '/api/v3/master/invoice-data',

  // Invoice Fixed Price
  invoiceFixedPrice: '/api/v3/transporter/invoice/fixed-price',

  // postage cost
  routeLocationPostageCost: '/api/v3/master/location/filter',
  findIdCompany: (shippingId) => `/api/v3/transporter/mou/company/${shippingId}`,
  findIdCompanyDetail: (shippingId) => `/api/v3/transporter/mou/${shippingId}/detail`,
  findRoutePostageCost: (shippingCostId, serviceType) => `/api/v3/transporter/mou/${shippingCostId}/route/${serviceType}`,
  findPostageCost: '/api/v3/master/shipping-cost-type/filter',
  postageCostList: '/api/v3/transporter/shipping-cost/filter',
  postageCostCreate: '/api/v3/transporter/shipping-cost',
  postageCostDetail: (postageCostId) => `/api/v3/transporter/shipping-cost/${postageCostId}`,
  deletePostageCost: (postageCostId) => `/api/v3/transporter/shipping-cost/${postageCostId}`,
  detailPostageCost: (idDetailPostageCost) => `/api/v3/transporter/shipping-cost/${idDetailPostageCost}`,
  serviceType: '/api/v3/transporter/mou/service',
  shipperRoute: '/api/v3/mou/shipper/company/route',

  // EMAIL
  emailList: '/api/v3/master/email-customer/filter',
  emailAddEdit: (emailId) => `/api/v3/master/email-customer/${emailId}`,
  emailAddEditList: '/api/v3/master/email-customer',
  deleteEmail: (emailId) => `/api/v3/master/email-customer/${emailId}`,

  // mou
  mou: '/api/v3/transporter/mou',
  mouTransporterRoute: '/api/v3/mou/transporter',
  detailWorkflowMou: (mouId) => `/api/v3/mou/workflow/${mouId}`,
  inboxAppoveMou: '/api/v3/mou/workflow/action/approve',
  inboxRejectMou: '/api/v3/mou/workflow/action/reject',
  inboxReviseMou: '/api/v3/mou/workflow/action/revise',

  // contract
  contractHistory: '/api/v3/workflow/history',
  contractDetail: '/api/v3/contract/company',
  contractCompanyList: '/api/v3/master/company/filter',

  // select info
  selectionInfo: '/api/v3/master/reason',

  // Invoice
  invoiceList3pl: '/api/v3/transporter/invoice/filter',
  invoiceCreate3pl: '/api/v3/transporter/invoice',
  invoiceCreateOrder: '/api/v3/transporter/transaction/filter',
  invoiceDetail: (invoiceId) => `/api/v3/transporter/invoice/${invoiceId}`,
  invoiceExport: '/api/v3/transporter/invoice/export',
  invoiceExportProforma: '/api/v3/transporter/invoice/export/proforma',

  // AccountInfo
  getAccountInfo: (companyUserId) => `/api/v3/user/${companyUserId}/profile`,
  // Dashboard
  shipmentSummary: '/api/v3/transporter/shipment/summary',
  dashboardList: '/api/v3/transporter/shipment/summary/list',

  // Notificatioin
  notification: '/notification/v1/notifications/web',

  // Eta
  etaList: '/api/v3/master/eta/',

  // Schedule
  // listTransporter: '/api/v3/master/company/transporter/filter',
  // listShipper: '/api/v3/master/company/shipper/filter',

  // master
  master: '/api/v3/master',

  // Damage and Missing
  baspList: '/api/v3/transporter/shipment/report/basp',
  baspDetail: '/api/v3/transporter/shipment/report/basp',

  // REPORTS
  reports: '/reports',

  // Shipping Problem
  accident: '/api/v3/transporter/shipment/report/accident',
  shippingProblemList: '/api/v3/transporter/shipment/report/accident/filter',
  shippingProblemProsesDetail: '/api/v3/transporter/shipment/report/accident',
  shippingProblemProses: (accidentId) => `/api/v3/transporter/shipment/report/accident/${accidentId}/process`,
  shippingProblemResolved: (accidentId) => `/api/v3/transporter/shipment/report/accident/${accidentId}/resolved`,

  // Schedule
  listTransporter: '/api/v3/master/company/transporter/filter',
  listShipper: '/api/v3/master/company/shipper/filter',
  listCalender: '/api/v3/transporter/calendar',

  // Wording
  fetchDetailByCodeWording: '/api/v3/master/parameters/wording/code',

  // P2P Lending
  p2pLending: '/api/v3/transporter/p2plending',
  topP2pLending: '/api/v3/transporter/mou/p2plending/filter',
  invoiceP2pLending: '/api/v3/transporter/invoice/p2plending/filter',

  // Shipper
  masterShipperList: '/api/v3/transporter/shipment/shipper',
};
