import Vue from 'vue';
import VuetifyDialog from 'vuetify-dialog';
import VueViewer from 'v-viewer';
import * as VueGoogleMaps from 'vue2-google-maps';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import Inputmask from 'inputmask';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as strings from './assets/strings';
import 'viewerjs/dist/viewer.css';
import './styles/global.scss';
import * as services from './providers/services';
import PhoneNumberInput from './components/Common/PhoneNumberInput.vue';
import PhoneNumberFormat from './components/Common/PhoneNumberFormat.vue';
import CommonTextInput from './components/Common/CommonTextInput.vue';
import CommonAutocompleteInput from './components/Common/CommonAutocompleteInput.vue';
import CommonDateInput from './components/Common/CommonDateInput.vue';
import CommonAutoCompleteItems from './components/Common/CommonAutoCompleteItems.vue';
import CommonError from './components/Common/CommonError.vue';
import DateAndTime from './components/Common/DateAndTime.vue';
import DialogViewImage from './components/Common/DialogViewImage.vue';
import DialogTermsConditions from './components/Common/DialogTermsConditions.vue';
import DialogViewPdf from './components/Common/DialogViewPdf.vue';
import ViewerImage from './components/Common/ViewerImage.vue';

import keys from '../keys';

import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.directive('mask', {
  bind(el, binding) {
    Inputmask.extendAliases({
      'custom-phone': {
        regex: String.raw`^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$`,
        keepStatic: false,
        greedy: false,
      },
    });
    Inputmask.extendAliases({
      'custom-integer': {
        placeholder: '',
        regex: '^\\d*$',
      },
    });
    Inputmask.extendAliases({
      time: {
        placeholder: '',
        regex: '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]?$',
      },
    });
    Inputmask.extendAliases({
      'idr-currency': {
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
        regex: '^[0]([1-9]{1})?|(\\d*[.]?\\d)*',
        // regex: '^(?:[0-9.])*$',
      },
    });
    Inputmask.extendAliases({
      idr: {
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
        regex: '^(?:[0-9.])*$',
        // regex: '0|^[0-9.]*$',
      },
    });
    Inputmask.extendAliases({
      'custom-notes': {
        placeholder: '',
        regex: '^[a-zA-Z0-9,_.]*$',
      },
    });
    Inputmask.extendAliases({
      'no-special-characters-allowed': {
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
        regex: '[A-Za-z0-9\n\\s]{1,255}',
      },
    });
    Inputmask.extendAliases({
      alphabetic: {
        placeholder: '',
        allowPlus: false,
        allowMinus: false,
        regex: '[A-Za-z\\s]{1,255}',
      },
    });
    const element = el.getElementsByTagName('INPUT')[0] || el.getElementsByTagName('textarea')[0];
    Inputmask(binding.value).mask(element);
  },

});
Vue.prototype.$_strings = strings;
Vue.prototype.$_services = services;
Vue.prototype.$_item_per_page = [5, 10, 25, 50, 100];

Vue.component('PhoneNumberInput', PhoneNumberInput);
Vue.component('PhoneNumberFormat', PhoneNumberFormat);
Vue.component('CommonTextInput', CommonTextInput);
Vue.component('CommonAutocompleteInput', CommonAutocompleteInput);
Vue.component('CommonDateInput', CommonDateInput);
Vue.component('DateAndTime', DateAndTime);
Vue.component('DialogViewImage', DialogViewImage);
Vue.component('DialogTermsConditions', DialogTermsConditions);
Vue.component('DialogViewPdf', DialogViewPdf);
Vue.component('CommonError', CommonError);
Vue.component('CommonAutoCompleteItems', CommonAutoCompleteItems);
Vue.component('ViewerImage', ViewerImage);

Vue.mixin({
  computed: {
    userAccess() {
      return store.state.user.userAccess || {};
    },
  },
  methods: {
    capitalizeFirstLetter: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    isEmailValid: (value) => /^[^<>()[\]\\,;:\\%#^\s@\\"$&!@]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}))$/.test(value),
    isPhoneNumberValid: (value) => /^(\+62)\s[1-9]{1}[0-9]{2}(?=.{6,14}$)(-[0-9]{4})+(?:-\d{1,4})$/.test(value),
  },
});
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
});
Vue.use(VueGoogleMaps, {
  load: {
    key: keys().googleMapsApiKey,
    libraries: 'places',
    region: 'id',
    language: 'id',
  },
});
Vue.use(VuetifyGoogleAutocomplete, {
  /*
    not used as loaded with component
    apiKey: key,
  */
  vueGoogleMapsCompatibility: true,
});
Vue.use(VueViewer);
Vue.config.productionTip = false;

if (process.env.VUE_APP_API_TARGET === 'production') {
  console.log = () => { };
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
